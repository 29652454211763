@import "rsuite/dist/rsuite.css";
.tab-container{
    padding: 0 !important;
    display: block;
    width: 100%;
    position: relative;
}
.filer-container{
    position: relative;
    padding: 0 30px;
}

.column-link-container .dropdown-container{
    min-width: 300px;
}

.global-filter{
    width: 500px;
}
.vertical-top{
    vertical-align: top;
}


.cus-dropdown .dropdown-container.open{
    display: block;
}

.btn{
    font-size: 14px !important;
}
/* .steperDesign div div div div a{
    line-height: 30px !important;
    color: #ffffff !important;
}
.steperDesign div div div div  {
background-color: #00976D !important;
} */ 
.StepButton-0-2-4.active{
    border:1px solid #00976D !important;
}
.steperDesign .active{
    background-color: #EAFFEC !important;  
    color:#00976D !important;
    
}
.steperDesign .completed{
    background-color: #00976D !important;    
}
.steperDesign .completed:hover{
    background-color: #00976D !important;     
}
.col-title{
    width: 100%;
}

.col-rulename{
    width: 100%;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color : #111827;
    word-wrap: break-word;
}
.col-rulename .col-title{
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
}
.col-rulename .market-place i{
    padding: 10px !important;
    margin: auto 5px;
}
.rule-and-or-container{
    display: flex;
}
.rule-and-or-container .form-check{
    padding: 10px 24px;
}
.title .page-title{
font-family: Plus Jakarta Sans;
font-size: 14px !important;
font-weight: 600 !important;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
color: #111827;
}
.custom-dropdown-container{
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
  
  }
  .market-place{
      font-family: Plus Jakarta Sans;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      color : #111827;
  }
  .export-link-container{
      font-family: Plus Jakarta Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      color: #9CA3AF;
  }
  .MuiDataGrid-cellContent
  {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color: #111827;
  }
  .col-action-container .dropdown-menu li p.dropdown-item.text-red {
    color: #DB624E;
  }
  .col-md-9 h6{
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 600 !important;
    line-height: 21px;
    letter-spacing: 0em;
    color: #111827;
  }
  .table-footer-left
  {
    font-family: Plus Jakarta Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    color: #6B7280;
  }
  .MuiDataGrid-columnHeaderTitle{
    font-weight:600!important;
  }