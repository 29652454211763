.sidebar {
  background-color: #001912;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 0px;
  overflow: hidden;
}
.sidebar {
  width: 70px;
}
.sidebar:hover {
  width: 260px;
}
.sidebar.open {
  width: 260px;
}
.brand-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logolarge {
  max-width: 100%;
  max-height: 100%;
}
.sidebar .header-wrapper .brand-logo {
  padding: 10px 20px 15px 20px;
}
.sidebar .brand-logo .logolarge {
  display: none;
}
.sidebar:hover .brand-logo .logosmall,
.sidebar.open .brand-logo .logosmall {
  display: none;
}
.sidebar:hover .brand-logo .logolarge,
.sidebar.open .brand-logo .logolarge {
  display: inline-flex;
}

.menus {
  padding-left: 0px;
}
.menus.bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.menu-items {
  padding: 13px 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.menu-items a {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: #ffffff !important;
}
.menu-items:hover,
.menu-items:focus,
.menu-items.active {
  background-color: #1b332c;
  border-radius: 8px;
}
.menu-items i {
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 15px;
}
.sidebar .menu-items a span {
  color: #82a49b !important;
  display: none;
}
.sidebar .menu-items.active a span {
  color: #ffffff !important;
}
.sidebar:hover .menu-items a span,
.sidebar.open .menu-items a span {
  display: inline-flex;
}
.sidebar:hover .menu-items i {
  margin-right: 15px;
}

/* Menu Icons */
.menu-items .dashboard-icon {
  background-image: url("../../assets/images/icons/dashboard.svg");
}
.menu-items:hover .dashboard-icon,
.menu-items:focus .dashboard-icon,
.menu-items.active .dashboard-icon {
  background-image: url("../../assets/images/icons/dashboard-active.svg");
}
.menu-items .bookshelfs-icon {
  background-image: url("../../assets/images/icons/bookshelf.svg");
}
.menu-items:hover .bookshelfs-icon,
.menu-items:focus .bookshelfs-icon,
.menu-items.active .bookshelfs-icon {
  background-image: url("../../assets/images/icons/bookshelf-active.svg");
}
.menu-items .ads-status-icon {
  background-image: url("../../assets/images/icons/ads.svg");
}
.menu-items:hover .ads-status-icon,
.menu-items:focus .ads-status-icon,
.menu-items.active .ads-status-icon {
  background-image: url("../../assets/images/icons/ads-active.svg");
}
.menu-items .goals-icon {
  background-image: url("../../assets/images/icons/goals.svg");
}
.menu-items:hover .goals-icon,
.menu-items:focus .goals-icon,
.goals-icon {
  background-image: url("../../assets/images/icons/goals-active.svg");
}
.menu-items .settings-icon {
  background-image: url("../../assets/images/icons/settings.svg");
}
.menu-items:hover .settings-icon,
.menu-items:focus .settings-icon,
.menu-items.active .settings-icon {
  background-image: url("../../assets/images/icons/settings.svg");
}
.menu-items .resources-icon {
  background-image: url("../../assets/images/icons/resources.svg");
}
.menu-items .FAQ-icon {
  background-image: url("../../assets/images/icons/quiz_FILL0_wght400_GRAD0_opsz24.svg");
  filter: brightness(100%) invert(0.5) sepia(0.2) hue-rotate(150deg);
}

.menu-items:hover .resources-icon,
.menu-items:focus .resources-icon,
.menu-items.active .resources-icon {
  background-image: url("../../assets/images/icons/resources-active.svg");
}
.menu-items .help-icon {
  background-image: url("../../assets/images/icons/help.svg");
}
.menu-items:hover .help-icon,
.menu-items:focus .help-icon,
.menu-items.active .help-icon {
  background-image: url("../../assets/images/icons/help-active.svg");
}
.menu-items .logout-icon {
  background-image: url("../../assets/images/icons/logout.svg");
}
.menu-items:hover .logout-icon,
.menu-items:focus .logout-icon,
.menu-items.active .logout-icon {
  background-image: url("../../assets/images/icons/logout.svg");
}

.hr-line-conainer {
  text-align: center;
}

/* Submenu styles */
.sidebar .submenu-dropdown {
  display: none;
  padding-left: 0px;
}
.sidebar:hover .submenu-dropdown.open,
.sidebar.open .submenu-dropdown.open {
  display: block;
}
.sidebar .submenu-dropdown .menu-items {
  border-left: 1px #194c38 solid;
  border-radius: 0;
  padding: 5px 0px;
  padding-left: 30px;
}
/* .sidebar:hover .submenu-dropdown, .sidebar.open .submenu-dropdown{
  display: block;
} */
.sidebar .submenu-dropdown .menu-items {
  list-style: none;
}
.sidebar .submenu-dropdown .menu-items a span {
  font-size: 14px;
}
.sidebar .submenu-dropdown .menu-items:hover,
.sidebar .submenu-dropdown .menu-items:focus,
.sidebar .submenu-dropdown .menu-items.active {
  background-color: #194c38;
  border-radius: 0 8px 8px 0;
}
.sidebar:hover .has-submenu,
.sidebar.open .has-submenu {
  background-image: url("../../assets/images/down-arrow.svg");
  background-position: right 10px top 20px;
  background-repeat: no-repeat;
}
.menu-items:hover,
.menu-items:focus,
.menu-items.active {
  background-color: #1b332c;
  border-radius: 8px;
}
.active-indicator {
  content: "";
  width: 6.67px;
  height: 6.67px;
  position: static;
  top: 0;
  right: 0;
  background: #00d26e;
  border-radius: 50%;

}
.activeicons{
  display: flex;
text-align: end;
}