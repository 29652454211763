@tailwind base;
@tailwind components;
@tailwind utilities;

body,
#root {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

