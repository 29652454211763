.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  color: white;
}
.page-title {
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  color: #111827;
}
.maincont nav {
  background-color: #ffffff !important;
  height: 70px;
  color: #9ca3af;
  border: 1px #f3f4f6 solid;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  padding-left: 80px;
  z-index: 10;
}
.maincont .table-footer-right nav {
  background-color: #ffffff !important;
  height: 70px;
  color: #9ca3af;
  border: 1px #f3f4f6 solid;
  position: relative !important;
  width: 100%;
  top: 0;
  right: 0;
  padding-left: 80px;
  z-index: 1;
}
.maincont .nav-link {
  padding-top: 0px;
  padding-bottom: 0px;
}
.nav-link,
.dropdown-item {
  font-family: "Averta", sans-serif;
  font-size: 16px !important;
  color: #6b7280 !important;
}
.dropdown-menu[data-bs-popper] {
  right: 0;
  left: auto !important;
}
.header-search {
  background-color: #f3f4f6;
  border-radius: 4px;
}
.profile-userName {
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-weight: 600 !important;
  color: #02976d;
}

.header-search button,
.header-search input {
  background-color: #f3f4f6;
  border: 0px;
  height: 40px;
  border-radius: 4px;
}
.header-search button {
  padding: 0 12px;
}
.header-search input {
  width: 340px;
  color: #9ca3af !important;
  outline: none;
  height: 40px;
  border-radius: 4px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.header-search button i {
  color: #9ca3af;
}
.profile-icon {
  position: relative;
  color: #6b7280;
}
.profile-icon .profile-img {
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  background-color: rgb(0, 197, 13);
}
.profile-icon i {
  position: absolute;
  bottom: -5px;
  left: 27px;
}
.profile-icon span {
  padding-left: 10px;
}
.dropdown-toggle::after {
  content: none !important;
}

.maincont .nav-item.dropdown {
  margin: 0px !important;
}
.custom-switch-container {
  cursor: default;
}
.form-switch .form-check-input {
  width: 40px !important;
  height: 24px !important;
  cursor: pointer;
  background-color: #4b5563 !important;
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e) !important;
}
.form-switch .form-check-input:checked {
  border-color: #44a048 !important;
  background-color: #44a048 !important;
}

.upgrade-to-pro-text {
  color: #00976d !important;
}
.redColor {
  color: red !important;
}
.redColor {
  color: red !important;
}

.redColor img {
  color: red !important;
}
.littleSpace div {
  display: flex;
  align-items: center;
  gap: 20px;
}
.navbar-nav .nav-link:hover {
  background-color: transparent !important;
}

.navbar-nav .nav-link:focus {
  box-shadow: none !important;
}
.navbar-nav .nav-item:focus > .nav-link,
.navbar-nav .nav-item:active > .nav-link {
  background-color: transparent !important;
  outline: none !important;
}
.profile-icon {
  position: relative;
  display: flex;
  color: #6b7280;
}
.profile-icon .profile-img {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid whitesmoke;
  background-color: white;
}
.skeleton-loading {
  width: 30px;
  height: 30px;
  object-fit: cover;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loadingAnimation 1.5s ease-in-out infinite;
  border-radius: 50%;
}

@keyframes loadingAnimation {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
