@import "rsuite/dist/rsuite.css";
.tab-container {
  padding: 0 !important;
  display: block;
  width: 100%;
  position: relative;
}
.filer-container {
  position: relative;
  padding: 0 30px;
}
.bulk-operation-container .dropdownContent ul.dropdown-menu {
  width: 100%;
}

.btnBackgoundRemove {
  background-color: #fff;
}
.column-link-container .dropdown-container {
  min-width: 300px;
}
.bookshelf-sticky {
  position: sticky;
  top: 137px;
  background-color: white;
  z-index: 1;
}
.global-filter {
  width: 500px;
}
.vertical-top {
  vertical-align: top;
}

.cus-dropdown .dropdown-container.open {
  display: block;
}

.btn {
  font-size: 14px !important;
}
.trash {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 4px;
}
button.btn.btn-default {
  margin-right: 10px;
}
.addTagModelContainers h4 {
  font-family: Plus Jakarta Sans !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 29px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #111827 !important;
}
.addTagModelContainers p {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b5563;
}
.addTagBtnContainer button:first-child {
  margin-right: 10px;
  border: 1px solid #00d26e;
}
.form-control.mb-2.mx-2 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #111827;
}
.modal.addTagModel4 .modal-dialog .addTagModels {
  width: 600px !important;
}
.modal.addTagModel4 .modal-dialog .addTagModels h4 {
  font-family: Plus Jakarta Sans !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #111827 !important;
}
.modal.addTagModel4 .modal-dialog .addTagModels p {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b5563;
}
.modal.addTagModel4 .modal-dialog .addTagModels .form-control {
  height: 40px !important;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #111827;
}
.modal.addTagModel4 .modal-dialog .addTagModels .row-item-container p {
  font-family: Plus Jakarta Sans !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #6b7280 !important;
}

.modal.addTagModel4
  .modal-dialog
  .addTagModels
  .row-item-container
  button.btn.btn-box {
  width: 40px !important;
  height: 40px !important;
  border: 1px solid #e5e7eb !important;
  background-color: #ffffff !important;
  color: #ff8b6b !important;
  padding: 0px;
  border-radius: 4px;
  margin-left: 10px;
}
.modal.addTagModel4 .modal-dialog .addTagModels .addTagBtnContainer button {
  width: 260px;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.addTagModelContainers input {
  font-family: Plus Jakarta Sans !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #9ca3af !important;
  height: 40px !important;
}
.react-confirm-alert-overlay {
  z-index: 99999999 !important;
  margin-left: 70px;
  background: none !important;
}
