body {
  background: #ffffff;
  background-image: none;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
}
body,
html,
.App,
#root,
.auth-wrapper {
  height: auto;
}
.underline {
  text-decoration: underline;
  /* color:#393939 !important;
  font-weight: 600 !important; */
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  background-color: #ffffff;
  color: white;
  z-index: 1;
  padding: 30px 30px 0px 30px;
}
.default-inner {
  background: #ffffff;
  transition: all 0.3s;
  position: relative;
  margin-top: 80px;
}
.contanier-type1 {
  width: 400px;
  margin: auto;
  padding: 30px;
  padding-bottom: 0px;
}
.maincont {
  margin-left: 70px;
}
main {
  padding: 35px;
}

/* login page */
.login-log {
  text-align: left;
  padding-bottom: 10px;
}
.login-log img {
  max-width: 200px;
}
.auth-login-icon {
  border: 1px solid #00d26e;
  border-radius: 4px;
  margin-top: 10px;
  margin-top: 20px;
  padding: 4px;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.auth-login-icon span {
  font-weight: 700 !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #171717;
  font-size: 16px;
}
.googleicon {
  max-width: 100%;
  text-align: center;
}
.googleicon button,
.facebookicon button {
  border: 0px !important;
  box-shadow: none !important;
  background-color: transparent;
  font-size: 14px;
}
.facebook-login-icon img {
  max-width: 20px;
  margin-right: 10px;
}
.facebookicon {
  padding: 8px 0px;
}
.forgot-password {
  right: 30px;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 14px !important;
  padding-top: 5px;
  color: #6b7280 ;
  margin: 0;
}
.forgot-password a {
  color: #00865D !important;
  text-decoration: none;
  font-family:"Plus Jakarta Sans";
  font-weight: 600 !important;
}

.form-control {
  border: 1px solid #e5e7eb !important;
  border-radius: 4px !important;
  height: 50px !important;
  color: #171717 !important;
  font-weight: 500 !important;
  font-family: "Plus Jakarta Sans";
  font-size: 16px !important;
}
.contanier-type1 .form-control:not([value=""]) {
  font-weight: 600 !important;
}

.login-checkbox {
  margin-right: 10px;
}
.checkbox-label {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-size: 14px;
  color: #9ca3af;
}
.btn {
  color: #171717 !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.btn-primary {
  border-radius: 4px !important;
  background: #00d26e !important;
  height: 50px !important;
  padding: 8px 16px;
  border-color: #00d26e !important;
}
.new-create-account {
  text-align: center;
  margin-top: 16px;
}
.new-create-account p {
  font-size: 16px;
  font-weight:600 !important;
  color: #171717 !important;
}
.new-create-account a {
  font-size: 16px;
  font-weight:700 !important;
  color: #00865D !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  color: #9ca3af;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #e5e7eb;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}
.validation-error {
  color: crimson;
}
.separator:not(:empty)::after {
  margin-left: 0.25em;
}
.btn[disabled] {
  background-color: #e9e9e9 !important;
  color: #171717 !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px !important;
  font-weight: 700 !important;
  border-color: #e9e9e9 !important;
}
.cont-s, .cont-s p {
  font-size: 12px !important;
  font-weight: 600 !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: center;
}
.cont-s a{
  font-size: 12px !important;
  font-weight: 600 !important;
  color:#00865D !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: center;
}
.custom-control-label {
  font-weight: 600 !important;
  font-size: 16px;
}
.default-inner h3 {
  font-size: 24px;
}
.password-cont {
  position: relative;
}
.show-password {
  position: absolute;
  right: 10px;
  top: 15px;
  color: #999;
}

.contanier-type2 {
  width: 500px;
  margin: auto;
  padding: 25px;
  padding-bottom: 0px;
}
.center-title {
  text-align: center;
  margin-bottom: 50px;
}
.contanier-type2 h2 {
  font-size: 32px !important;
  font-weight: 600 !important;
  color: #171717;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.contanier-type2 .sub-title {
  font-size: 14px;
  color: #393939;
}
.contanier-type2 h3 {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px;
  color :#171717 !important;
}
.contanier-type2 p {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #393939;
}
.contanier-type2 .col-md-10 h4 {
  font-family: Plus Jakarta Sans;
  font-size: 16px !important;
  font-weight: 600 !important;
  color:#111827;
}
.contanier-type2 .col-md-10 p {
  font-family: Plus Jakarta Sans;
  font-size: 12px !important;
  font-weight: 500 !important;
  color:#393939;
}

.onboard-cont {
  border: 1px #00d26e solid;
  border-radius: 12px;
  padding: 20px 30px;
  margin: 10px auto;
}
.onboard-warring {
  border: 1px #ffbd06 solid;
  background-color: #fffef5;
}
.onboard-success-btn button {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
}
.onboard-success-btn button.explore {
  border: 1px #00d26e solid;
  background-color: #ffffff !important;
  color: #00865d !important;
}
.contanier-type1 .mb-3 h3{
  font-size: 24px;
  font-weight: 600 !important;
}
.contanier-type1 .mb-3 p{
  font-size: 16px;
  font-weight: 600 !important;
  color:#393939 !important;
}
.contanier-type1 .mb-3 a{
  font-size: 16px;
  font-weight: 600 !important;
  color :#00865D !important;
}
.contanier-type1 h3{
font-family: Plus Jakarta Sans;
font-size: 24px;
font-weight: 600 !important;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color: #171717;
}
.back-to-login{
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color :#00865D !important;
}
.button-text
{
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #171717;
}
.main-cont-header .last-sync{
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 600 !important;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #6B7280;
  margin-top: 5px;
  display: flex;
}
.main-cont-header .lastspan{
  padding-left: 5px;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 400 !important;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #6B7280;
}
.main-cont-header .last-sync i img{
  color: #6B7280;
}
